.category-section-grid-right:nth-child(3),
.category-section-grid-right:nth-child(3) img,
.category-section-grid-right:nth-child(4) img,
.category-section-grid-right:nth-child(4) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.category-section-grid-right img,
.category-section-grid-left,
.category-section-grid-right.ant-skeleton-image {
  aspect-ratio: 1/1;
  height: 100% !important;
}
.category-section-card {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.category-section-card span {
  /* background: var(--white-color); */
  padding: 5px;
  padding-left: 0;
  cursor: pointer;
  width: fit-content;
}
.category-section-card span svg {
  font-weight: normal;
}
.category-section-card .content p {
  margin-bottom: 0;
  font-weight: 500;
}
.category-section-card .content .d-flex p {
  font-size: 1.3rem;
  margin: 0;
}
.category-section-card .content .theme-btn {
  padding: 3px 10px;
}
.category-section-card .content {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background: #ffffffca;
  color: #000;
  transition: all 1s;
  padding: 10px;
}
.category-section-card:hover .content {
  bottom: 0;
}
.category-section-card-left .content {
  left: 0;
}
.category-section-card-left:hover .content {
  bottom: 0;
}
.variant-modal-card {
  display: flex;
  justify-content: space-between;
  background: #f5f3f0;
  padding: 10px;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.variant-modal-card:last-child {
  margin-bottom: 0;
}
.variant-modal-card .theme-btn {
  padding: 3px 10px;
}
.variant-modal-card p {
  margin: 0;
  font-weight: 500;
}
.disc-price del,
.variant-modal-card p del {
  color: #7b7a7a;
  font-weight: normal;
  margin-left: 5px;
}
.variant-modal-card img {
  width: 50px;
  height: 50px;
  margin-right: 0.7rem;
}
@media (max-width: 568px) {
  .category-section-grid-left {
    aspect-ratio: inherit;
  }
  .category-section-card {
    height: auto;
    overflow: initial;
    width: 100%;
  }
  .category-section-card img {
    height: auto !important;
  }
  .category-section-card .content {
    position: static;
    width: 100%;
  }
  .category-section-grid-right img {
    height: 100%;
  }
  .category-section-grid-right:nth-child(1),
  .category-section-grid-right:nth-child(3) {
    padding-right: 6px;
  }
  .category-section-grid-right:nth-child(2),
  .category-section-grid-right:nth-child(4) {
    padding-left: 6px;
  }
  .category-section-grid-right .theme-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .category-section-grid-right .category-section-card .content > p {
    height: auto;
  }
  .variant-drawer-mobile .ant-drawer-header {
    padding: 1rem;
  }
  .variant-drawer-mobile .ant-drawer-header .ant-drawer-header-title {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
