.box-container {
  width: 768px;
  padding-top: 0px !important;
  padding: 40px;
}

.create-review {
  text-align: left;
  padding-bottom: 25px;
  padding-top: 25px;
}


.product-reviewImage {
  /* display: flex;
       align-items: flex-end; */
  /* width: 30%; */
}

.variantNameReview {
  text-align: left;
  width: 70%;
}

.over-all-rating {
  display: flex;
  justify-content: space-between;
}

.container-clear {
  text-align: right;
  cursor: pointer;
  color: var(--theme-color-dark);
}

.headline {
  margin-top: 10px;
}

.submit-review-button {
  width: 30%;
  background: var(--theme-color-dark);
  color: #fff;
}

.submit-review {
  text-align: right;
}

.star-review input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.all-review-scroll {
  height: 700px;
  overflow-x: scroll;
}

.all-review-scroll::-webkit-scrollbar {
  display: none;
}

.product-reviews-all {
  padding: 0px 0 0px;
}

@media (min-width: 320px) and (max-width: 480px) {


  .product-reviews-all {
    padding: 100px 0 0px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px;
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 0;
    border-right: none;
  }

  .overall-ratings>img {
    margin-right: 1rem;
  }
}

.rating-spinner {
  text-align: center;
  padding: 60px 0px;
  color: var(--theme-color-dark);
}

.css-1at2wjx {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -.1px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  padding: 3px 5px;
  background-color: var(--theme-color-dark);
  ;
}