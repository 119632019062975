footer {
  /* background: url(../../assets/Images/footer-banner.jpg) no-repeat center; */
  /* background-size: cover; */
  /* height: 70vh; */
  /* padding-top: 300px; */
  position: relative;
  background: #f5f3f0;
}

.footer-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  /* padding-right: 2.5rem; */
  z-index: -1;
}

footer h3 {
  color: var(--theme-color-dark);
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}

footer p,
footer li,
footer li svg {
  color: #000 !important;
  /* color: var(--theme-color-dark); */
}

footer li {
  margin-bottom: 5px;
}

footer a {
  /* font-size: 1.1rem; */
  font-style: italic;
  color: #000 !important;
}

.footer-bottom {
  background: var(--theme-color-dark);
  color: var(--white-color);
}

.footer-bottom a {
  color: var(--white-color) !important;
}

.footer-conatct-links a {
  padding: 10px;
  font-size: 1rem;
}

.payments-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.payments-icons img {
  width: 50px;
}


@media (max-width:578px) {
  .payments-icons {
    justify-content: center;
    margin: 1rem 0;
    flex-wrap: wrap;
  }
}



/* new  */
.christmas-ten {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .1;
  object-fit: cover;
  right: 0;
}