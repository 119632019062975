.contact-iconss {
  margin-bottom: 2rem;
  align-items: flex-start;
}
.contact-iconss svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: var(--theme-color-dark);
  color: var(--white-color);
  border-radius: 50%;
  margin-right: 10px;
}

.contact-iconss-location p {
  width: 70%;
  margin-bottom: 0;
}
.conatct-link-card {
  padding: 1rem;
  background-color: #f5f3f0;
  display: flex;
  height: 100%;
}
.conatct-link-card .links {
  display: flex;
  flex-direction: column;
}
.conatct-link-card svg {
  font-size: 3rem;
  color: var(--theme-color-dark);
  margin-right: 1rem;
}
.card-box {
  /* padding: 30px 20px; */
}

.contact-form-tab .nav-pills .nav-link {
  border: 1px solid var(--theme-color-dark);
}
.contact-form-tab .nav-pills .nav-link.active {
  background: var(--theme-color-dark) !important;
  color: var(--white-color) !important;
}
