.customer-dashboard-page p {
  margin-bottom: 0;
}

.customer-dashboard-page .ant-steps-item-finish .ant-steps-icon .anticon {
  color: var(--black-color);
}

.customer-dashboard-page .ant-steps-item-finish .ant-steps-item-title::after {
  background: var(--black-color) !important;
}

/* ==========================Sidebar Address start =================================*/

.address-card {
  width: 100%;
  height: 100% !important;
  border-radius: 5px;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

.address-content {
  text-align: left;
}

.address-content p {
  font-size: 100%;
}

.address-icon-row {
  margin-bottom: 0px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

@media screen and (max-width: 760px) {
  .address-box-profile {
    height: 120%;
  }
}

.address-plus {
  text-align: center;
}

.address-icon {
  color: #495057;
}

/* ===========================Sidebar Address end================================= */

/* =================================Address Modal start================================*/
.address-modal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
}

/*============================== Address Modal end ===================================*/
.ant-drawer {
  z-index: 99999 !important;
}

.mobile-drawer .ant-drawer-header .ant-drawer-header-title,
.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  justify-content: flex-end;
}

.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  padding-top: 10px;
}

.select-slots-drawer .ant-drawer-header {
  border: none;
  padding: 0 !important;
}

.select-slots-drawer .ant-drawer-body {
  padding: 0;
}

.mobile-drawer .ant-drawer-header .ant-drawer-header-title,
.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  justify-content: flex-end;
}

.customer-detail-edit {
  position: absolute;
  top: 0;
  right: 8px;
  cursor: pointer;
  color: var(--theme-color-white);
  font-size: 1.6rem;
}

.customer-detail-edit {
  position: absolute;
  top: 0;
  right: 8px;
  cursor: pointer;
  color: var(--theme-color-white);
  font-size: 1.6rem;
}

.customer-address-edit svg {
  cursor: pointer;
  font-size: 1.6rem;
}

.customer-address-edit {
  display: flex;
  color: var(--theme-color-dark);
}

.customer-add-address {
  font-size: 2rem;
}

.address-types-btn {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  padding: 1px 10px;
}

.address-offcanvas-icons {
  font-size: 20px;
}