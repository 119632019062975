.btn-send {
  background-color: var(--theme-color-dark);
  /* color: var(); */
}
.captcha-box {
  position: relative;
}
.captcha-box > div {
  position: absolute;
  top: 40%;
  right: 7%;
  cursor: pointer;
  transform: translate(-7%, -40%);
}
