.footer-policy-section .slick-slide > div{
    padding: 0 10px;
    height: 100%;
}
.footer-policy-section .slick-slide > div .card,
.footer-policy-section .slick-slide > div .card .card-body{
    height: 100%;
}
.footer-policy-section .slick-slide .card{
    /* background: var(--theme-color-light); */
    background: #f5f3f0;
    border: none;
    /* color: var(--white-color); */
}
.footer-policy-section .slick-slide .card .card-body{
    display: flex;
    /* align-items: center; */
}
.footer-policy-section .slick-slide .card .card-body img{
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 10px;
    align-self: center;
}
.footer-policy-section .slick-slide .card p b{
    font-size: 1.3rem;
}
@media (max-width:568px) {
    .footer-policy-section .slick-slide .card .card-body{
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 2.2rem;
    }
}