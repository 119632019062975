@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

:root {
  /* --theme-color-light: #b4a284; */
  /* --theme-color-light: #304b3f; */
  /* --theme-color-light: #89aaa4; */
  /* --theme-color-light: #ef1a24; */
  --theme-color-light: #880c0a;
  /* --theme-color-dark: #462d04; */
  --theme-color-dark: #880c0a;
  /* --theme-color-dark: #ef1a24; */
  --white-color: #fff;
  --black-color: #434343;
  --text-color: #434343;
}

@font-face {
  font-family: "headingfont";
  src: url(./assets/fonts/Okra-Medium.ttf);
}

/* @font-face {
  font-family: "Helvetica Neue";
  src: url(./assets/fonts/FreeSans.ttf);
} */
/* @font-face {
  font-family: "gill";
  src: url(./assets/fonts/Gill-Sans.otf),
    url(./assets/fonts/Gill-Sans-Bold.otf) format(otf),
    url(./assets/fonts/Gill-Sans-Light.otf) format(otf),
    url(./assets/fonts/Gill-Sans-Medium.otf) format(otf);
} */
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue";
  color: var(--text-color);
}

ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

a {
  text-decoration: none !important;
  color: var(--black-color) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Libre Baskerville", serif; */
  font-family: "headingfont";
  /* font-family: "Dancing Script", cursive; */
  /* font-family: "Raleway", sans-serif; */
  /* font-family: "EB Garamond", serif; */
}

.heading-style h2 {
  margin-bottom: 0;
  color: var(--black-color);
}

.heading-style h3 {
  color: var(--black-color);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.theme-btn {
  background: var(--theme-color-dark);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  color: var(--white-color) !important;
}

.theme-btn-disabled {
  background: var(--theme-color-dark);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  opacity: .5;
  cursor: not-allowed;
  color: var(--white-color) !important;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 9;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-prev,
.slick-next {
  background-color: #fff;
  font-size: 1.5rem;
  color: var(--black-color);
  height: 35px;
  width: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 2px rgba(148, 146, 245, 0.15);
}

.slick-prev:hover,
.slick-next:hover {
  background: #fff;
  color: var(--theme-color-dark);
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-theme,
.main-heading {
  color: var(--theme-color-dark) !important;
}

.blank-section {
  min-height: 100vh;
}

.login-icon-change-pass {
  float: right;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
}

.footer-policy-section .slick-track {
  display: flex !important;
}

.footer-policy-section .slick-slide {
  height: auto;
}

.payment-box {
  margin-top: -5rem;
}

.payment-box .icon {
  width: 200px;
  height: 200px;
  font-size: 8rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
  color: var(--theme-color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

/* .zsiq_theme1 .zsiq_user {
  height: 100%;
  width: 100%;
  border-radius: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  background-color: #fff;
  transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  background: #880c0a !important;
} */

#zsiq_float .zsiq_flt_rel {
  background-color: #880c0a !important;
  /* background: transparent !important; */
}

.zsiq_theme1 .zsiq_flt_rel {
  position: relative;
  padding: 3px;
  width: 60px;
  border-radius: 60px;
  height: 60px;
  font-size: 14px;
  background-color: #328a59;
  line-height: 100%;
  top: -40px;
}

.zsiq_theme1 .siqicon:before {
  margin: auto;
  z-index: 1111;
  font-size: 27px;
  line-height: 60px;
  color: #fff;
  /* display: none; */
}

.zsiq_theme1 .zsiq_user {
  /* background: url(./assets/Images/christmas/message-icon.png) !important; */
}

@media (min-width:390px) {
  .other-address-option {
    margin: 0.5rem !important;
  }
}

@media (max-width:390px) {
  .other-address-option {
    margin: 0 0 15px !important;
  }
}


/* christmas theme*/
.christmas-five {
  position: absolute;
  height: 150px;
  width: 150px !important;
  top: 0;
  right: 0;
}

.christmas-one {
  position: absolute;
  /* height: 150px; */
  width: 120px !important;
  top: 0;
  left: 0;
}

.christmas-nine {
  position: absolute;
  top: -65px;
  right: 30px;
  width: 100px;
}


@media (max-width:900px) {
  .christmas-five {
    position: absolute;
    height: 150px;
    width: 150px !important;
    top: inherit;
    right: 0;
    bottom: -9px;
    transform: rotate(90deg);
    opacity: 0.7;
    z-index: 0;
  }

  .christmas-one {
    position: absolute;
    /* height: 150px; */
    width: 120px !important;
    top: 0;
    left: 0;
    opacity: .7;
  }

  .christmas-nine {
    position: absolute;
    top: -80px;
    width: 100px;
    right: inherit;
    left: 50%;
    transform: translateX(-50%) rotate(-15deg);
  }
}