@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Courgette&family=Dancing+Script:wght@400..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap);
.distributors-section {
  background: #f5f3f0;
}
.process {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}
.process__item {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  position: relative;
  padding: 15px 35px;
  transition: 0.4s ease-in-out;
}
.process__item:hover {
  background: #f2f2f2;
}
.process__item:hover .process__number {
  transform: translateY(5px);
  color: var(--theme-color-dark);
}
.process__number {
  font-size: 90px;
  -webkit-text-stroke: 1px var(--theme-color-dark);
  display: block;
  color: transparent;
  font-weight: 700;
  transition: 0.4s ease-in-out;
}
.process__title {
  display: block;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
  color: var(--theme-color-dark);
  text-transform: uppercase;
  margin-top: 30px;
}
.process__title a {
  color: var(--theme-color-dark) !important;
}
.process__subtitle {
  display: block;
  font-size: 20px;
  color: #8c8c8c;
  margin-top: 30px;
  line-height: 1.5;
}
.vm-card :is(h2,p) {
  color: var(--white-color);
}
.vm-card {
  text-align: center;
  border: 1px solid var(--white-color);
  padding: 1rem;
  height: 100%;
  border-radius: 5px;
}

/* new  */

.ps-section--team {
  /* background-color: #fff; */
}

.ps-section--team .ps-section__title {
  margin-bottom: 20px;
}

.ps-section--team .ps-section__header p {
  margin-bottom: 25px;
}

.ps-section--team .ps-list--dot {
  margin-bottom: 35px;
}

.vm-card .icon {
  background: var(--theme-color-dark);
  width: 120px;
  height: 120px;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.work-process-area {
  overflow: hidden;
  /* margin-bottom: -8px; */
}

.works-process-items {
  float: left;
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: 30px;
}

.works-process-items .item {
  padding: 0 15px;
}

.works-process-items::after {
  border-top: 1px solid #e7e7e7;
  content: "";
  height: 1px;
  left: -50%;
  margin-top: 40px;
  position: absolute;
  top: 0;
  width: 200%;
  z-index: -1;
}

.work-process-area {
  overflow: hidden;
  margin-bottom: -8px;
}

.works-process-items .item .info h4 {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: capitalize;
}

.works-process-items .item .info span {
  color: var(--theme-color-dark);
  display: block;
  font-family: Poppins, sans-serif;
  margin-bottom: 10px;
}

.works-process-area .works-process-items .info p {
  margin: 0;
}

.works-process-items .item .icon {
  height: 160px;
  position: relative;
  text-align: center;
}

.works-process-items .item .icon i {
  background: var(--theme-color-dark) none repeat scroll 0 0;
  border-radius: 50%;
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 50px;
  height: 100px;
  left: 50%;
  line-height: 100px;
  margin-left: -50px;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 100px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
}

.works-process-items .item .icon.icon-down i {
  bottom: 0;
  top: auto;
  transition: all 0.35s ease-in-out;
}

.works-process-items .item:hover .icon i {
  background: #ef1a24 none repeat scroll 0 0;
  color: #fff;
}
.work-process-area.bg-gray .works-process-items .item .vertical-line span {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .vertical-line {
  line-height: 0;
  margin: 15px 0;
  position: relative;
  text-align: center;
}

.works-process-items .item .vertical-line span {
  background: #f4f4f4 none repeat scroll 0 0;
  border-radius: 50%;
  color: #232323;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 800;
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 50px;
}

.work-process-area.bg-gray .works-process-items .item .vertical-line span {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .info h4 {
  font-weight: 500;
}

.works-process-items .item .info p {
  margin: 0;
}

.works-process-items .item .vertical-line span::before {
  background: #d1d1d1 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  transition: all 0.6s ease-in-out;
  width: 1px;
  z-index: -1;
}

.work-process-area.bg-gray
  .works-process-items
  .item
  .vertical-line
  span::before {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .vertical-line span::after {
  background: #d7d7d7 none repeat scroll 0 0;
  border-radius: 50%;
  bottom: 100px;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transition: all 0.6s ease-in-out;
  width: 10px;
  z-index: -1;
}

.works-process-items .item .vertical-line.bottom span::before {
  bottom: auto;
  top: 0;
}

.works-process-items .item .vertical-line.bottom span::after {
  bottom: auto;
  top: 100px;
}

.works-process-items .item .info {
  text-align: center;
}

.works-process-items .item:hover .vertical-line span::before {
  height: 0;
}

.works-process-items .item:hover .vertical-line span::after {
  bottom: 0;
}

.works-process-items .item:hover .vertical-line.bottom span::after {
  top: 0;
}

.inner-icon i {
  font-size: 3rem;
  color: var(--theme-color-dark);
}

.clenzrooms-how-work-section {
  padding: 120px 0px;
  background-color: #f2fcfc;
}

.about-ruby {
  background: #f5f3f0;
}
.about-ruby {
  padding-top: 70px;
}
.about-ruby .line-shape {
  left: 0;
  top: 60px;
}

.about-ruby-item .serial {
  left: 0;
  right: 0;
  top: -15px;
  z-index: 2;
  color: #fff;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 100%;
  background-color: var(--theme-color-dark);
}
.about-ruby-item .inner-icon {
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.about-ruby-item .inner-icon::before,
.about-ruby-item .inner-icon::after {
  left: 0;
  top: 10px;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: var(--theme-color-dark);
  transition: 500ms all ease;
}
.about-ruby-item .inner-icon::after {
  top: 0;
  background-color: #fff;
}
.about-ruby-item .inner-text h3 {
  color: #101010;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
}
.about-ruby-item:hover .inner-icon::after {
  background-color: var(--theme-color-dark);
}
.about-ruby-item:hover .inner-icon::before {
  background: var(--white-color);
}

.about-ruby-item:hover i {
  color: #fff;
}
.vm-section {
  position: relative;
  background: var(--theme-color-dark);
}
.vm-section .svg-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
}
.vm-section svg {
  background: var(--theme-color-dark);
  height: clamp(3.125rem, 2.1154rem + 4.4872vw, 7.5rem);
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.vision-mission-section {
  padding-top: clamp(7.125rem, 8.1154rem + 8.4872vw, 9.5rem);
  margin-bottom: 7rem;
}
.vm-right-img{
  width: 320px;
  height: 320px;
  -webkit-mask-image: url(/static/media/vm-bg.c84ea7e2.png);
          mask-image: url(/static/media/vm-bg.c84ea7e2.png);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: cover;
          mask-size: cover;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.vm-right-img img{
  height: 70%;
  width: 70%;
  border-radius: 50%;
}
.vm-divider{
  display: flex;
  list-style: none;
}
.vm-divider li{
  width: 20px;
  height: 20px;
  background: var(--white-color);
  border-radius: 50%;
  margin-right: 1rem;
}
.vm-divider li:first-child{
  width: 80px;
  border-radius: 15px;
}
.vm-bottom-img-section{
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: -50%;
  overflow: hidden; */
  /* position: relative;
  bottom: -70%; */
  margin-top: -4rem !important;
  transform: translateY(50%);
  background: transparent;
}
.vm-bottom-img-left{
  border: 2px solid var(--white-color);
  transform: rotate(12deg);
}
.vm-bottom-img-right{
  border: 2px solid var(--white-color);
  transform: rotate(-12deg);
}
@media (max-width: 1199px) {
  .ps-section--team .ps-section__title::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .ps-section--team .ps-section__header {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .ps-section--team .ps-section__content .col-xs-12 {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .ps-section--team .ps-section__content .col-xs-12 {
    width: 100%;
  }
  .vision-mission-section{
    padding-top: 5rem;
    overflow-x: hidden;
    margin-bottom: 0;
  }
  .vm-right-img{
    margin: 0 auto;
  }
  .vm-bottom-img-section{
    transform: translateY(0);
    margin-top: 2rem !important;
  }
}

@media (min-width: 768px) {
  .process {
    display: inline-block;
  }
  .process__item {
    width: 49%;
    display: inline-block;
  }
  
}
@media (min-width: 1200px) {
  .process {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .process__item {
    width: 100%;
  }
  .process__item:not(:last-of-type)::after {
    content: "";
    width: 1px;
    height: 75%;
    background: #8c8c8c;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0.2;
    transform: translateY(-50%);
  }
}

.captcha-box > div {
    position: absolute;
    top: 40%;
    right: 5%;
    transform: translate(-5%, -40%);
    cursor: pointer;
  }

 
.quantity-btn-box {
    display: flex;
    background: var(--white-color);
    color: var(--theme-color-light);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--theme-color-light);
    height: 32px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.quantity-btn-box .quantity-btn svg {
    color: var(--theme-color-light);
}

.quantity-btn-box .quantity-delete-btn {
    height: 100%;
    padding: 4px;
}

.quantity-btn-box .quantity-btn {
    font-size: .9rem;
    height: 100%;
}

.quantity-btn-box button {
    background: transparent;
    border: none;
    color: var(--theme-color-light);
    padding: 0;
    height: 100%;
    padding: 0 2px;
}

.quantity-btn-box button svg {
    margin-top: -4px;
}

.quantity-btn-box input {
    max-width: 30px;
    text-align: center;
    margin: 0 5px;
    background: transparent;
    border: none;
    color: var(--theme-color-light);
    border-left: 1px solid var(--theme-color-light);
    border-right: 1px solid var(--theme-color-light);
    height: 100%;
}

.quantity-delete-btn {
    height: 100%;
    background: var(--white-color);
    color: var(--theme-color-light);
    font-size: 1.4rem;
    margin-left: 5px;
    border-left: 1px solid var(--theme-color-light);
}

.quantity-delete-btn cart-remove-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.customer-dashboard-page p {
  margin-bottom: 0;
}

.customer-dashboard-page .ant-steps-item-finish .ant-steps-icon .anticon {
  color: var(--black-color);
}

.customer-dashboard-page .ant-steps-item-finish .ant-steps-item-title::after {
  background: var(--black-color) !important;
}

/* ==========================Sidebar Address start =================================*/

.address-card {
  width: 100%;
  height: 100% !important;
  border-radius: 5px;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

.address-content {
  text-align: left;
}

.address-content p {
  font-size: 100%;
}

.address-icon-row {
  margin-bottom: 0px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

@media screen and (max-width: 760px) {
  .address-box-profile {
    height: 120%;
  }
}

.address-plus {
  text-align: center;
}

.address-icon {
  color: #495057;
}

/* ===========================Sidebar Address end================================= */

/* =================================Address Modal start================================*/
.address-modal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
}

/*============================== Address Modal end ===================================*/
.ant-drawer {
  z-index: 99999 !important;
}

.mobile-drawer .ant-drawer-header .ant-drawer-header-title,
.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  justify-content: flex-end;
}

.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  padding-top: 10px;
}

.select-slots-drawer .ant-drawer-header {
  border: none;
  padding: 0 !important;
}

.select-slots-drawer .ant-drawer-body {
  padding: 0;
}

.mobile-drawer .ant-drawer-header .ant-drawer-header-title,
.select-slots-drawer .ant-drawer-header .ant-drawer-header-title {
  justify-content: flex-end;
}

.customer-detail-edit {
  position: absolute;
  top: 0;
  right: 8px;
  cursor: pointer;
  color: var(--theme-color-white);
  font-size: 1.6rem;
}

.customer-detail-edit {
  position: absolute;
  top: 0;
  right: 8px;
  cursor: pointer;
  color: var(--theme-color-white);
  font-size: 1.6rem;
}

.customer-address-edit svg {
  cursor: pointer;
  font-size: 1.6rem;
}

.customer-address-edit {
  display: flex;
  color: var(--theme-color-dark);
}

.customer-add-address {
  font-size: 2rem;
}

.address-types-btn {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  padding: 1px 10px;
}

.address-offcanvas-icons {
  font-size: 20px;
}
.checkout-img {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 0.3rem;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  cursor: initial !important;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.delete-cart-card-btn {
  position: absolute;
  top: -15px;
  right: -25px;
  font-size: 1.5rem;
}

.quantity {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0;
}

.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}

.quantity__input {
  width: 32px;
  height: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dee0ee;
  border-bottom: 2px solid #dee0ee;
  border-left: 1px solid #dee0ee;
  border-right: 2px solid #dee0ee;
  background: var(--theme-color-white);
  color: #8184a1;
  font-size: 12px;
}

.quantity__minus,
.quantity__plus {
  display: block;
  width: 22px;
  height: 23px;
  margin: 0;
  background: #eeeeee;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
}

.prod-price {
  color: var(--black-color);
  font-weight: 700;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #d9d9d9;
}

.order-summary.sticky-top {
  transition: 0.3s;
  z-index: 0 !important;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  list-style: none;
  border-bottom: 1px solid #ccc;
  width: auto;
}

.coupon-card-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #adaaaa;
  border-radius: 5px;
  border-left: 6px solid var(--black-color);
  border-right: 6px solid var(--black-color);
}

.coupon-card-box p.font-weight-bold {
  font-size: 1.2rem;
}

.coupon-card-box svg {
  font-size: 2rem;
}

.cartcheckout-accordion {
  border-radius: 5px !important;
  font-family: inherit !important;
  background: var(--theme-color-white) !important;
}

.cartcheckout-accordion .ant-collapse-header {
  border-radius: 12px 12px 0 0;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.checkbox input {
  margin-right: 10px;
}

.address-links {
  margin-top: 10px;
}

.address-links a {
  margin-right: 10px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: transparent !important;
  border: none !important;
}

.checkout-bx {
  padding: 15px 0;
  border-bottom: 1px solid #f9f6f6;
}

.checkout-bx .position-relative:last-child hr {
  display: none;
}

.checkout-bx:last-child {
  border-bottom: none;
}

.checkout-bx hr:last-child {
  /* display: none; */
}

.checkout-img {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 0.3rem;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  cursor: initial !important;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.checkout-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
  border-radius: 3px;
}

.checkout-btn:hover {
  color: #ee1b2f;
  background: white;
  border: 1px #ee1b2f solid;
  border-radius: 3px;
}

.boxes {
  /* padding: 1rem; */
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.boxes input {
  margin-right: 5px;
}

.boxes p {
  margin-bottom: 0;
}

.address-box {
  font-size: 14px;
}

.address-box p {
  margin-bottom: 10px;
}

.address-box p b {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.address-box input {
  margin-right: 10px;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  /* padding: 20px; */
  padding: 0;
  margin-top: 20px;
  margin-bottom: 45px;
  border-radius: 5px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  border-bottom: 4px solid var(--black-color);
}

.order-summary > div {
  padding: 20px;
}

.order-summary.sticky-top {
  transition: 0.3s;
  z-index: 0 !important;
}

.order-summary .order-summary-table td svg {
  margin-right: 5px;
}

.order-bottom-card {
  padding: 10px 20px !important;
  line-height: 1.2;
  background: #f3f3f3;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.checkout-box {
  border: none;
}

.btnicons {
  width: 20px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

.address-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%); */
  border: 1px solid var(--theme-color-dark);
}

/* //////////Select address end /////////////// */

/* ===========Login.js=========== */

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p::before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0px;
  color: #767676;
  position: relative;
  padding: 0px 5px;
}

.but button {
  margin-top: 10px;
  width: 100%;
}

.cart-box .account-dropdown-content {
  position: absolute;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  min-width: 180px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 20px 20px 30px;
  z-index: 9999;
  margin-top: 15px;
  transform: translateY(-2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.but-button {
  margin-top: 10px;
  width: 100%;
  background: white;
  color: #50b05d;
  border: 1px solid #50b05d;
}

.but-button :hover {
  background: #50b05d !important;
  color: white !important;
  border: 1px solid #50b05d !important;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

@media (max-width: 568px) {
  .delete-cart-card-btn {
    position: absolute;
    top: -15px;
    right: 8px;
  }

  .quantity__input {
    width: 50%;
  }

  .quantity__minus,
  .quantity__plus {
    width: 30%;
  }

  .cquantity {
    margin-top: 10px !important;
  }

  .add-new-address {
    font-size: 0.8rem;
    padding: 10px;
    margin-left: 10px;
  }
}

.cartcheckout-accordion .ant-collapse-item-active .ant-collapse-header {
  background: #f3f3f3;
  border-radius: 5px;
}

.cart-accordian-icons {
  font-size: 25px;
}

.cartcheckout-accordion .ant-collapse-header .cart-accordian-icons {
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: 0.4s all;
  color: var(--theme-color-dark);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.cartcheckout-accordion
  .ant-collapse-item-active
  .ant-collapse-header
  .cart-accordian-icons {
  background-color: var(--white-color);
}

.address-offcanvas {
  width: 35% !important;
}

.cart-address-icons {
  font-size: 35px;
  display: flex;
  justify-content: center;
}

.cartcheckout-accordion .ant-collapse-item .add-addres-btn {
  display: none;
  margin-left: auto;
  margin-right: 1rem;
}

.cartcheckout-accordion .ant-collapse-item-active .add-addres-btn {
  display: inline-block;
}

.coupon-main-box {
  /* height: 300px;
  overflow-y: auto; */
}
.coupon-main-box .coupon-card {
  /* height: 200px; */
  border: 1px solid var(--theme-color-dark);
  border-top: 4px solid var(--theme-color-dark);
  border-radius: 5px;
  padding: 0 !important;
}
.coupon-main-box .coupon-card-code {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: var(--theme-color-dark);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.coupon-main-box .coupon-card .coupon-terms-condition .cursor-pointer svg,
.coupon-main-box .view-more svg {
  font-size: 1rem;
}
.coupon-main-box .coupon-card-active {
  /* height: auto;
  overflow-y: auto; */
}

/* height: auto;
  overflow-y: auto; */

.coupon-main-box .apply-coupon-btn {
  height: auto !important;
  background: var(--theme-color-dark) !important;
  border: none;
  border-top: 1px solid #f3f3f3;
  color: var(--theme-color-dark) !important;
  border-radius: 0 0 5px 5px;
  padding: 0 !important;
  width: 100%;
}
.coupon-main-box .apply-coupon-btn button {
  background: transparent !important;
  border: none;
  border-radius: 0 0 5px 5px;
  width: 100%;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.coupon-main-box,
.coupon-main-box .coupon-card .terms-show,
.coupon-main-box .coupon-card {
  padding-right: 1rem;
  scrollbar-width: auto;
  scrollbar-color: #a3a3a3 #ffffff;
}

.coupon-main-box::-webkit-scrollbar,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar,
.coupon-main-box .coupon-card::-webkit-scrollbar {
  width: 5px;
}

.coupon-main-box::-webkit-scrollbar-track,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar-track,
.coupon-main-box .coupon-card::-webkit-scrollbar-track {
  background: #ffffff;
}

.coupon-main-box::-webkit-scrollbar-thumb,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar-thumb,
.coupon-main-box .coupon-card::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 10px;
  border: 3px solid #a3a3a3;
}
@media (max-width: 992px) {
  .address-offcanvas {
    width: auto !important;
  }
}
.contact-iconss {
  margin-bottom: 2rem;
  align-items: flex-start;
}
.contact-iconss svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: var(--theme-color-dark);
  color: var(--white-color);
  border-radius: 50%;
  margin-right: 10px;
}

.contact-iconss-location p {
  width: 70%;
  margin-bottom: 0;
}
.conatct-link-card {
  padding: 1rem;
  background-color: #f5f3f0;
  display: flex;
  height: 100%;
}
.conatct-link-card .links {
  display: flex;
  flex-direction: column;
}
.conatct-link-card svg {
  font-size: 3rem;
  color: var(--theme-color-dark);
  margin-right: 1rem;
}
.card-box {
  /* padding: 30px 20px; */
}

.contact-form-tab .nav-pills .nav-link {
  border: 1px solid var(--theme-color-dark);
}
.contact-form-tab .nav-pills .nav-link.active {
  background: var(--theme-color-dark) !important;
  color: var(--white-color) !important;
}

.coupon-modal .modal-title {
  text-align: center;
}
.coupon-modal .main-svg {
  margin: 0 auto;
  font-size: 3rem;
}
.coupon-close-btn {
  position: absolute;
  right: 0.5rem;
  top: 0;
  opacity: 1;
}
.coupon-close-btn svg {
  font-size: 2rem;
}

.change-pass-icon {
  margin: 0 auto;
  font-size: 3rem;
  text-align: center;
}
.login-modal-icon {
  font-size: 3rem;
  text-align: center;
}

.lef-rigth-border {
  position: relative;
}
.lef-rigth-border::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}
.lef-rigth-border::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 45%;
  transform: translateY(-50%);
  height: 1px;
  background: #777;
}


.product-title2,
.product-title2 p{
    margin-bottom: 0;
}
.product-title2 p{
font-weight: 600;
}
.profile-card {
  border: 1px solid var(--theme-color-dark);
  /* padding: 1rem; */
  border-radius: 5px;
  overflow: hidden;
}

.profile-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 2rem;
  /* margin-bottom: 1rem; */
}

.profile-card .d-flex {
  padding: 1rem;
}

/* .profile-card .img p, */
.profile-card .content p {
  margin: 0;
  font-weight: bold;
}

.profile-card .img lable,
.profile-card .content lable {
  font-size: 0.8rem;
  color: #606060;
}

.profile-card .btns {
  background-color: var(--theme-color-dark);
  /* padding: 1rem; */
  display: flex;
  justify-content: space-between;
}

.profile-card .btns button {
  color: var(--white-color);
  background: transparent;
  border: none;
  padding: 1rem;
  text-align: center;
  width: 30%;
}

.profile-card .btns button:nth-child(2) {
  border-left: 1px solid var(--white-color);
  border-right: 1px solid var(--white-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.four-card {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  height: 100%;
}

.four-card .head {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.four-card .fields p {
  margin: 0;
  font-size: 1.2rem;
  margin-top: 5px;
}

.four-card .fields lable {
  font-size: 0.9rem;
  color: #606060;
}

.four-card .fields {
  /* display: flex; */
  padding: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 1rem;
}

.four-card .head .icon {
  width: 40px;
  height: 40px;
  padding: 5px;
  background: var(--theme-color-dark);
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.5rem;
  margin-right: 0.7rem;
}

.four-card .notification-card p {
  margin-bottom: 0;
}

.four-card .notification-card {
  height: 150px;
  overflow-y: auto;
  margin-top: 1rem;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.four-card .notification-card {
  scrollbar-width: 5px;
  scrollbar-color: var(--theme-color-dark);
}

/* Chrome, Edge, and Safari */
.four-card .notification-card::-webkit-scrollbar {
  width: 6px;
}

.four-card .notification-card::-webkit-scrollbar-track {
  background: #ffffff;
}

.four-card .notification-card::-webkit-scrollbar-thumb {
  background-color: var(--theme-color-dark);
  border-radius: 10px;
  /* border: 3px solid #ffffff; */
}

.order-card {
  border: 1px solid var(--theme-color-dark);
  border-radius: 5px;
  margin: 0;
  padding: 1rem 0.2rem;
}

.order-card .border {
  padding: 1rem;
  border-radius: 5px;
}

.address-card .fields,
.order-card .border .fields {
  display: flex;
  align-items: end;
  margin-bottom: 10px;
  justify-content: space-between;
}

.order-card .border .fields:last-child {
  margin: 0;
}

.address-card p,
.order-card .border .fields p {
  margin: 0;
}

.order-card .border .fields lable {
  /* font-size: .8rem; */
  color: #8d8b8b;
  /* margin-right: 10px; */
}

.order-inner-card {
  overflow: hidden;
  border-radius: 5px;
  border-bottom: 3px solid var(--theme-color-dark);
}

.order-inner-card .content {
  padding: 10px;
}

.order-inner-card p {
  margin-bottom: 0;
}

.order-inner-card span {
  color: #747373;
  font-size: 0.9rem;
}

.address-card {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--theme-color-dark) !important;
  overflow: hidden;
}

.address-card span {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--theme-color-dark);
  color: var(--white-color);
  padding: 2px 10px;
  border-radius: 0 0 0 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.address-card span svg {
  margin-top: -3px;
}

.frequently-card .theme-btn {
  padding: 5px 10px;
  font-size: 0.8rem;
}

.add-address-slider .slick-track {
  display: flex !important;
}

.add-address-slider .slick-slide {
  height: inherit !important;
}

.add-address-slider .slick-slide>div {
  height: 100%;
}
.btn-send {
  background-color: var(--theme-color-dark);
  /* color: var(); */
}
.captcha-box {
  position: relative;
}
.captcha-box > div {
  position: absolute;
  top: 40%;
  right: 7%;
  cursor: pointer;
  transform: translate(-7%, -40%);
}

.btn-send {
  background-color: var(--theme-color-dark);
  /* color: var(); */
}
.table {
  --bs-table-bg: initial !important;
  --bs-table-color: initial !important;
}
.table .table-color {
  background: var(--theme-color-dark) !important;
  color: #fff !important ;
}

.table-katagiri {
  background-color: #1aaf20 !important;
  color: #fff !important;
}

.coimbatore {
  background: var(--theme-color-dark) !important;
  color: #fff !important;
}

.table-info {
  background-color: blue !important;
}

.Cuddalore {
  background-color: #ff9800 !important;
  color: #fff;
}

.dharmapuri {
  background-color: #673ab7 !important;
  color: #fff;
}

.dindigul {
  background-color: #989803 !important;
  color: #fff;
}

.Erode {
  background-color: #ff00ff !important;
  color: #fff;
}

.Kanchipuram {
  background-color: var(--theme-color-dark) !important;
  color: #fff;
}

.karur {
  background-color: #0091ff !important;
  color: #fff;
}

.Krishnagiri {
  background-color: #b8860b !important;
  color: #fff;
}

.Nagapattinam {
  background-color: #ff9800 !important;
  color: #fff;
}
.Namakkal {
  background-color: #ff00ff !important;
  color: #fff;
}

.Ramnad {
  background-color: #989803 !important;
  color: #fff;
}

.tanjore {
  background-color: #1aaf20 !important;
  color: #fff;
}
.distributors-form-box{
    background: #faf8f8;
}
.distributors-form.row {
  background: #faf8f8;
  padding: 1rem 0;
  height: 100%;
}
.distributors-acoordion{
    padding: 10px;
    border: 1px solid #d9d9d9;
}
.distributors-acoordion .ant-collapse{
  border: none;
}
.distributors-acoordion :where(.css-dev-only-do-not-override-11xg00t).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
    border-radius: 0;
}
.distributors-acoordion .ant-collapse {
  margin-bottom: 1rem;
  overflow: hidden;
}
.distributors-acoordion .ant-collapse-header {
  flex-direction: row-reverse;
  background: #fff !important;
  border: none !important;
  align-items: center;
}
.distributors-acoordion .ant-collapse-content {
  padding: 0.7rem;
  font-size: 1rem;
  max-height: 200px;
  overflow-y: auto;
}
.distributors-acoordion .ant-collapse-content-box > div {
  columns: 2 100px;
}
.distributors-acoordion .ant-collapse-content b {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}
.distributors-acoordion .ant-collapse-content p {
  margin-bottom: 10px;
  text-transform: capitalize;
}
.distributors-acoordion .ant-collapse-content p:last-child {
  margin-bottom: 0;
}
@media (max-width: 568px) {
  .distributors-acoordion .ant-collapse-header-text {
    padding-right: 1rem;
  }
  .distributors-acoordion{
    margin-bottom: 1.5rem;
  }
}

footer {
  /* background: url(../../assets/Images/footer-banner.jpg) no-repeat center; */
  /* background-size: cover; */
  /* height: 70vh; */
  /* padding-top: 300px; */
  position: relative;
  background: #f5f3f0;
}

.footer-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  /* padding-right: 2.5rem; */
  z-index: -1;
}

footer h3 {
  color: var(--theme-color-dark);
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}

footer p,
footer li,
footer li svg {
  color: #000 !important;
  /* color: var(--theme-color-dark); */
}

footer li {
  margin-bottom: 5px;
}

footer a {
  /* font-size: 1.1rem; */
  font-style: italic;
  color: #000 !important;
}

.footer-bottom {
  background: var(--theme-color-dark);
  color: var(--white-color);
}

.footer-bottom a {
  color: var(--white-color) !important;
}

.footer-conatct-links a {
  padding: 10px;
  font-size: 1rem;
}

.payments-icons {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.payments-icons img {
  width: 50px;
}


@media (max-width:578px) {
  .payments-icons {
    justify-content: center;
    margin: 1rem 0;
    flex-wrap: wrap;
  }
}



/* new  */
.christmas-ten {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .1;
  object-fit: cover;
  right: 0;
}

.nav-logo {
  width: 80px;
}

.main-navbar .navbar-toggler {
  border: none !important;
  color: var(--theme-color-dark);
  padding: 0;
  font-size: 1.5rem !important;
}

button:focus-visible,
button:focus {
  border: none !important;
  box-shadow: none !important;
}

.main-nav-right-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav-right-links li:first-child {
  margin-bottom: -2px;
}

.main-nav-right-links li {
  font-size: 1.5rem;
  /* margin-right: 1rem; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.main-nav-right-links li:last-child {
  margin: 0;
}

.main-nav-right-links li span {
  font-size: 0.8rem;
  background: var(--theme-color-light);
  color: var(--white-color);
  border-radius: 5px;
  padding: 4px;
  margin-left: 10px;
  /* width: 30px; */
  text-align: center;
}

.main-nav-right-links li svg {
  color: var(--black-color);
}

/* Main Navbar Menu */
.main-nav-menu-links {
  /* overflow: hidden; */
}

.fixed-nammenu {
  background: var(--white-color);
}

.main-nav-menu-links a {
  margin-right: 2rem;
  font-size: 1.1rem;
  position: relative;
  /* text-transform: uppercase; */
  display: inline-block;
  transition: 0.3s;
}

.main-nav-menu-links a:hover {
  color: var(--theme-color-dark) !important;
}

/* .main-nav-menu-links a::before{
    content: "\f0d7";
    position: absolute;
    font-family: "FontAwesome";
    color: var(--theme-color-dark);
    top: 0;
    left: 50%;
    transform: translate(-50%,-50px);
    transition: .3s; 
    opacity: 0;  
} */
.main-nav-menu-links a:hover::before {
  opacity: 1;
  transform: translate(-50%, -15px);
  z-index: 999;
}

.main-nav-menu-sm-links li a {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 10px;
}

.main-nav-menu-sm-links li:last-child a {
  margin: 0;
}

.main-nav-menu-sm-links li a {
  font-size: 1.35rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
}

.main-nav-menu-sm-links li a svg {
  color: var(--theme-color-dark);
}

.main-nav-menu-sm-links li a:hover {
  background: var(--theme-color-dark);
}

.main-nav-menu-sm-links li a:hover svg {
  color: var(--white-color);
}

/* .main-nav-menu-links li .dropdown-toggle::after{
    content: "\f107";
    font-family: "FontAwsome";
    border: none;
} */
.login-icon svg {
  font-size: 1.5rem;
}

.login-icon {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-menu.show {
  left: -100% !important;
  /* transform: translate3d(-140px, 0px, 0px) !important; */
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent !important;
  color: var(--black-color) !important;
}

.dropdown-toggle:hover {
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-toggle {
  margin-right: 10px;
}

.login-dropdown .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle::after {
  border: none !important;
  /* font: 'fontawesome';
    content: "\f107"; */
  content: "\f107" !important;
  font-family: FontAwesome;
  vertical-align: 0 !important;
  font-weight: bold;
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    margin-top: -4px !important;
  }
}

@media (max-width: 992px) {
  .fixed-nammenu .border-top.border-bottom {
    border-top: none !important;
  }

  .navbar-brand {
    margin-left: 1rem;
  }

  .offcanvas-body .dropdown-menu.show {
    border: none !important;
    padding: 0;
    margin: 0;
  }

  .main-navbar-offcanvas {
    max-width: 80% !important;
  }

  .main-navbar-offcanvas .contact-links {
    align-items: center;
  }

  .main-navbar-offcanvas .contact-links svg {
    font-size: 1.7rem;
    padding: 5px;
    background-color: var(--theme-color-dark);
    color: var(--white-color);
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (min-width:992px) and (max-width:1120px) {
  .main-nav-menu-links a {
    margin-right: 1.6rem !important;
    font-size: 1.0rem;
    position: relative;
    display: inline-block;
    transition: 0.3s;
  }

}
.box-container {
  width: 768px;
  padding-top: 0px !important;
  padding: 40px;
}

.create-review {
  text-align: left;
  padding-bottom: 25px;
  padding-top: 25px;
}


.product-reviewImage {
  /* display: flex;
       align-items: flex-end; */
  /* width: 30%; */
}

.variantNameReview {
  text-align: left;
  width: 70%;
}

.over-all-rating {
  display: flex;
  justify-content: space-between;
}

.container-clear {
  text-align: right;
  cursor: pointer;
  color: var(--theme-color-dark);
}

.headline {
  margin-top: 10px;
}

.submit-review-button {
  width: 30%;
  background: var(--theme-color-dark);
  color: #fff;
}

.submit-review {
  text-align: right;
}

.star-review input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.all-review-scroll {
  height: 700px;
  overflow-x: scroll;
}

.all-review-scroll::-webkit-scrollbar {
  display: none;
}

.product-reviews-all {
  padding: 0px 0 0px;
}

@media (min-width: 320px) and (max-width: 480px) {


  .product-reviews-all {
    padding: 100px 0 0px;
  }

  .customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px;
  }

  .overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 0;
    border-right: none;
  }

  .overall-ratings>img {
    margin-right: 1rem;
  }
}

.rating-spinner {
  text-align: center;
  padding: 60px 0px;
  color: var(--theme-color-dark);
}

.css-1at2wjx {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -.1px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  padding: 3px 5px;
  background-color: var(--theme-color-dark);
  ;
}
/* font-family: "Caveat", cursive; */
/* font-family: "Courgette", cursive; */
/* font-family: "Dancing Script", cursive; */
.variant-image {
  width: 100px;
}
.fs-14 {
  font-size: 14px;
}
.product-detail-content {
  /* height: 601px; */
}
.product-short-description {
  /* font-size: 20px; */
  font-weight: 500;
}
.prod-yousave {
  font-family: var(--theme-font-secondary);
  font-size: 16px;
}
.product-detail-accordion {
  /* height: 180px; */
  margin-top: 0.6rem;
  /* overflow-y: auto; */
}

.product-detail-accordion::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.product-detail-accordion::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  max-height: 50%;
  height: 50%;
}
.product-detail-accordion::-webkit-scrollbar-thumb {
  height: 10px !important;
}

.product-detail-accordion::-webkit-scrollbar-thumb {
  opacity: 0.6;
  /* height: 20px; */
  border-radius: 10px;
  background-color: var(--theme-color-dark);
}
.product-detail-accordion
  :where(.css-dev-only-do-not-override-1adbn6x).ant-collapse-borderless {
  background: transparent !important;
}

.product-detail-accordion
  :where(.css-dev-only-do-not-override-1adbn6x).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center;
}
.product-detail-accordion .ant-collapse-item-active .ant-collapse-header-text {
  padding-top: 0 !important;
}
.product-detail-accordion .ant-collapse-header-text {
  font-size: 1rem;
  font-weight: 500;
}
.product-detail-accordion .ant-collapse-content-box p {
  font-size: 14px;
}

.product-share-btns {
  position: absolute;
  top: -25px;
  right: 0;
}
.product-share-btns ul {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  list-style: none;
}
.product-share-btns .p-share-btn,
.product-share-btns ul li a,
.product-share-btns ul li button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var(--white-color) !important;
  background: var(--theme-color-dark) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  margin-top: 10px;
}
.product-share-btns .p-share-btn {
  position: relative;
  z-index: 999;
  background: var(--white-color) !important;
  color: var(--theme-color-dark) !important;
}
.product-share-btns ul li {
  position: absolute;
  top: -10px;
  /* display: none; */
  opacity: 0;
  transition: 1s;
}

.product-share-btns ul li:nth-child(2) {
  /* transition-delay: 0.2s; */
}
.product-share-btns ul li:nth-child(3) {
  /* transition-delay: 0.4s; */
}
.product-share-btns ul li:nth-child(4) {
  /* transition-delay: 0.6s; */
}
.product-share-btns ul li:nth-child(5) {
  /* transition-delay: .8s; */
}
.activ-share-btn .p-share-btn {
  color: var(--white-color) !important;
  background: var(--theme-color-dark) !important;
}
.activ-share-btn li:nth-child(5),
.activ-share-btn li:nth-child(2),
.activ-share-btn li:nth-child(3),
.activ-share-btn li:nth-child(6),
.activ-share-btn li:nth-child(4) {
  opacity: 1;
}
.activ-share-btn li:nth-child(2) {
  top: 28px;
}
.activ-share-btn li:nth-child(3) {
  top: 66px;
}
.activ-share-btn li:nth-child(4) {
  top: 105px;
}
.activ-share-btn li:nth-child(5) {
  top: 142px;
}
.activ-share-btn li:nth-child(6) {
  top: 180px;
}
.product-share-btns .p-share-btn {
  cursor: pointer;
  margin-top: 0;
  border: 1px solid var(--theme-color-dark);
}

.product-detail-img {
  /* height: 601px !important; */
  aspect-ratio: 1/1;
}
.product-detail-img > div {
  height: 100% !important;
}
.product-detail-img img {
  height: 100% !important;
}

.variants .detailscard img {
  width: 100px;
}

.overall-ratings {
  display: flex;
  background-color: #ffffff;
  padding: 0.5rem 0;
  border-right: 2px solid rgba(101, 119, 134, 0.22);
}
.review-card {
  padding: 1rem;
  margin: 1rem;
  position: relative;
  box-shadow: 0 0 10px #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
}
.review-card .css-rwwnk2,
.review-card .userInfoSection,
.review-card .css-1v6g5ho {
  padding: 0;
}

.review-card .css-1n0nrdk {
  height: 60px;
  overflow-y: auto;
  font-size: 0.9rem;
  padding-right: 10px;
}
.review-card .css-1n0nrdk::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6c6a6a;
}
.review-card .UserInformationProductReviews {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.review-card .verified-buyers-icon {
  display: flex;
  align-items: center;
}
.review-card .verified-buyers-icon img {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}
.overall-ratings > img {
  width: 200px;
  height: 200px;
  margin-right: 2rem;
}

.ratings {
  font-size: 36px;

  letter-spacing: -0.3px;
  line-height: 50px;
  margin-right: 0.5rem;
}
.ratings-exact {
  font-size: 36px;

  line-height: 40px;
  letter-spacing: -0.3px;
}

.ratings-upto {
  font-size: 36px;

  line-height: 40px;
}

.ratings-static {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(0, 19, 37, 0.92);
  display: block;
  margin-top: 0.5rem;
}

.ratings-verified {
  line-height: 16px;
  flex: 2 1;
}

.verified-upto {
  font-size: 13px;

  line-height: 20px;
  letter-spacing: 0px;
  color: rgba(0, 19, 37, 0.72);
}

.writereview {
  font-size: 14px;

  line-height: 20px;
  letter-spacing: 0px;
  font-stretch: normal;
  font-style: normal;
  color: rgba(0, 19, 37, 0.72);
  padding-bottom: 0.5rem;
}

.customer-reviews {
  padding: 25px 75px;
}

.customer-reviews-Description {
  display: flex;
  width: 100%;
  /* border-bottom: 1px solid rgba(101, 119, 134, 0.12); */
  padding: 25px 55px;
}
.product-detail-content .ant-select-selection-item p {
  margin: 0;
}
.pers-box {
  padding: 1rem;
  border: 1px solid #d9d9d9;
  width: 50%;
}
.pers-btns button {
  background: var(--white-color);
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-right: 1rem;
  padding: 5px 15px;
}
.pers-btns button:focus-visible {
  outline: none !important;
}
.pers-btns button.active {
  background: var(--theme-color-light);
  color: var(--white-color);
  border-color: var(--theme-color-light);
}
.pers-btns button:last-child {
  margin: 0;
}
.pers-input-box {
  /* gap: 10px; */
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* .pers-input-box > div:nth-child(1){
  margin-right: 13px;
} */
.pers-input-box > div {
  width: 100%;
}
.pers-input-box input {
  border: 1px solid #d9d9d9;
  padding: 5px 11px;
  border-radius: 6px;
  width: 100%;
}
.pers-input-box p {
  margin: 0;
}
.pers-prev {
  margin-top: 1rem;
  padding: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.detailscard {
  width: 100px;
  margin-right: 1rem;
}

.detailscard p {
  padding: 5px;
  margin-bottom: 0;
  line-height: normal;
}
.related-product-slider .slick-list .slick-track {
  margin: 0;
}
.product-detail-accordion td{
  padding: 10px;
}
.product-detail-accordion tbody,
  .product-detail-accordion td,
  .product-detail-accordion tfoot,
  .product-detail-accordion th,
  .product-detail-accordion thead,
  .product-detail-accordion tr {
    border-width: 1px !important;
  }
  .product-box img{
    aspect-ratio: 1/1;
    width: 100%;
  }
@media (max-width: 1090px) and (min-width: 480px) {
  .product-detail-img {
    height: 370px !important;
  }
  .product-detail-content {
    height: auto;
  }
  .product-share-btns {
    top: -20px;
  }
}
@media (max-width: 992px) and (min-width: 480px) {
  .product-detail-img {
    /* height: 370px !important; */
  }

  .product-detail-content {
    height: auto !important;
  }

  .buttons.detailsbtn {
    margin-top: 0;
  }

  .products-inner .prod-title {
    font-size: 2rem;
    padding-right: 3rem;
  }

  .product-short-description {
    font-size: 0.9rem !important;
    padding-right: 3rem;
  }
  .pers-box {
    width: 100%;
  }
}

@media (max-width: 568px) {
  .product-detail-accordion table {
    width: 100% !important;
    overflow: scroll !important;
  }
  
  .product-detail-content {
    margin-top: 1rem;
  }
}

.category-image-header {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}
.product-filter-list{
  display: flex;
}
.category-wrapper-header {
  width: 50%;
  text-align: center;
}
.category-wrapper-header a{
  display: flex;
}
.top-bar{
    /* color: var(--theme-color-dark); */
    color: #fff;
    background: var(--theme-color-light);
    /* font-weight: bold; */
    font-size: 1.1rem;
}
:root {
  /* --theme-color-light: #b4a284; */
  /* --theme-color-light: #304b3f; */
  /* --theme-color-light: #89aaa4; */
  /* --theme-color-light: #ef1a24; */
  --theme-color-light: #880c0a;
  /* --theme-color-dark: #462d04; */
  --theme-color-dark: #880c0a;
  /* --theme-color-dark: #ef1a24; */
  --white-color: #fff;
  --black-color: #434343;
  --text-color: #434343;
}

@font-face {
  font-family: "headingfont";
  src: url(/static/media/Okra-Medium.ca30cf6c.ttf);
}

/* @font-face {
  font-family: "Helvetica Neue";
  src: url(./assets/fonts/FreeSans.ttf);
} */
/* @font-face {
  font-family: "gill";
  src: url(./assets/fonts/Gill-Sans.otf),
    url(./assets/fonts/Gill-Sans-Bold.otf) format(otf),
    url(./assets/fonts/Gill-Sans-Light.otf) format(otf),
    url(./assets/fonts/Gill-Sans-Medium.otf) format(otf);
} */
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue";
  color: var(--text-color);
}

ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}

a {
  text-decoration: none !important;
  color: var(--black-color) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Libre Baskerville", serif; */
  font-family: "headingfont";
  /* font-family: "Dancing Script", cursive; */
  /* font-family: "Raleway", sans-serif; */
  /* font-family: "EB Garamond", serif; */
}

.heading-style h2 {
  margin-bottom: 0;
  color: var(--black-color);
}

.heading-style h3 {
  color: var(--black-color);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.theme-btn {
  background: var(--theme-color-dark);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  color: var(--white-color) !important;
}

.theme-btn-disabled {
  background: var(--theme-color-dark);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  opacity: .5;
  cursor: not-allowed;
  color: var(--white-color) !important;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 9;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-prev,
.slick-next {
  background-color: #fff;
  font-size: 1.5rem;
  color: var(--black-color);
  height: 35px;
  width: 35px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 2px rgba(148, 146, 245, 0.15);
}

.slick-prev:hover,
.slick-next:hover {
  background: #fff;
  color: var(--theme-color-dark);
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-theme,
.main-heading {
  color: var(--theme-color-dark) !important;
}

.blank-section {
  min-height: 100vh;
}

.login-icon-change-pass {
  float: right;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
}

.footer-policy-section .slick-track {
  display: flex !important;
}

.footer-policy-section .slick-slide {
  height: auto;
}

.payment-box {
  margin-top: -5rem;
}

.payment-box .icon {
  width: 200px;
  height: 200px;
  font-size: 8rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
  color: var(--theme-color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

/* .zsiq_theme1 .zsiq_user {
  height: 100%;
  width: 100%;
  border-radius: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  background-color: #fff;
  transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  background: #880c0a !important;
} */

#zsiq_float .zsiq_flt_rel {
  background-color: #880c0a !important;
  /* background: transparent !important; */
}

.zsiq_theme1 .zsiq_flt_rel {
  position: relative;
  padding: 3px;
  width: 60px;
  border-radius: 60px;
  height: 60px;
  font-size: 14px;
  background-color: #328a59;
  line-height: 100%;
  top: -40px;
}

.zsiq_theme1 .siqicon:before {
  margin: auto;
  z-index: 1111;
  font-size: 27px;
  line-height: 60px;
  color: #fff;
  /* display: none; */
}

.zsiq_theme1 .zsiq_user {
  /* background: url(./assets/Images/christmas/message-icon.png) !important; */
}

@media (min-width:390px) {
  .other-address-option {
    margin: 0.5rem !important;
  }
}

@media (max-width:390px) {
  .other-address-option {
    margin: 0 0 15px !important;
  }
}


/* christmas theme*/
.christmas-five {
  position: absolute;
  height: 150px;
  width: 150px !important;
  top: 0;
  right: 0;
}

.christmas-one {
  position: absolute;
  /* height: 150px; */
  width: 120px !important;
  top: 0;
  left: 0;
}

.christmas-nine {
  position: absolute;
  top: -65px;
  right: 30px;
  width: 100px;
}


@media (max-width:900px) {
  .christmas-five {
    position: absolute;
    height: 150px;
    width: 150px !important;
    top: inherit;
    right: 0;
    bottom: -9px;
    transform: rotate(90deg);
    opacity: 0.7;
    z-index: 0;
  }

  .christmas-one {
    position: absolute;
    /* height: 150px; */
    width: 120px !important;
    top: 0;
    left: 0;
    opacity: .7;
  }

  .christmas-nine {
    position: absolute;
    top: -80px;
    width: 100px;
    right: inherit;
    left: 50%;
    transform: translateX(-50%) rotate(-15deg);
  }
}
.disclaimer-box .ant-collapse {
  margin-bottom: 1rem;
  overflow: hidden;
}
.disclaimer-box .ant-collapse-header {
  flex-direction: row-reverse;
  background: #fff !important;
  border: none !important;
  align-items: center;
}
.disclaimer-box .ant-collapse-content {
  padding: 0.7rem 1rem 1rem;
  font-size: 1rem;
}
@media (max-width: 568px) {
  .disclaimer-box .ant-collapse-header-text {
    padding-right: 1rem;
  }
}

.aboutus-section{
    background: #F5F3F0;
}

@media (max-width:568px) {
    .aboutus-section{
        margin: 0;
    }   
}
.best-seller-card{
    width: 20% !important;
    padding: 0 .4rem !important;
}
.best-seller-card p{
    margin-bottom: 0;
}
.product-img-skeleton{
    /* height: 400px; */
    aspect-ratio: 1/1;
}
.product-img-skeleton img{
    
}
@media (max-width:992px) {
    .best-seller-card{
        width: 50% !important;
    }    
}
@media (max-width:568px) {
    .best-seller-card{
        width: 100% !important;
        padding: 0 .75rem !important;
    }
}
.category-section-grid-right:nth-child(3),
.category-section-grid-right:nth-child(3) img,
.category-section-grid-right:nth-child(4) img,
.category-section-grid-right:nth-child(4) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.category-section-grid-right img,
.category-section-grid-left,
.category-section-grid-right.ant-skeleton-image {
  aspect-ratio: 1/1;
  height: 100% !important;
}
.category-section-card {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.category-section-card span {
  /* background: var(--white-color); */
  padding: 5px;
  padding-left: 0;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.category-section-card span svg {
  font-weight: normal;
}
.category-section-card .content p {
  margin-bottom: 0;
  font-weight: 500;
}
.category-section-card .content .d-flex p {
  font-size: 1.3rem;
  margin: 0;
}
.category-section-card .content .theme-btn {
  padding: 3px 10px;
}
.category-section-card .content {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background: #ffffffca;
  color: #000;
  transition: all 1s;
  padding: 10px;
}
.category-section-card:hover .content {
  bottom: 0;
}
.category-section-card-left .content {
  left: 0;
}
.category-section-card-left:hover .content {
  bottom: 0;
}
.variant-modal-card {
  display: flex;
  justify-content: space-between;
  background: #f5f3f0;
  padding: 10px;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.variant-modal-card:last-child {
  margin-bottom: 0;
}
.variant-modal-card .theme-btn {
  padding: 3px 10px;
}
.variant-modal-card p {
  margin: 0;
  font-weight: 500;
}
.disc-price del,
.variant-modal-card p del {
  color: #7b7a7a;
  font-weight: normal;
  margin-left: 5px;
}
.variant-modal-card img {
  width: 50px;
  height: 50px;
  margin-right: 0.7rem;
}
@media (max-width: 568px) {
  .category-section-grid-left {
    aspect-ratio: inherit;
  }
  .category-section-card {
    height: auto;
    overflow: initial;
    width: 100%;
  }
  .category-section-card img {
    height: auto !important;
  }
  .category-section-card .content {
    position: static;
    width: 100%;
  }
  .category-section-grid-right img {
    height: 100%;
  }
  .category-section-grid-right:nth-child(1),
  .category-section-grid-right:nth-child(3) {
    padding-right: 6px;
  }
  .category-section-grid-right:nth-child(2),
  .category-section-grid-right:nth-child(4) {
    padding-left: 6px;
  }
  .category-section-grid-right .theme-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  .category-section-grid-right .category-section-card .content > p {
    height: auto;
  }
  .variant-drawer-mobile .ant-drawer-header {
    padding: 1rem;
  }
  .variant-drawer-mobile .ant-drawer-header .ant-drawer-header-title {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.category-banner-section {
  width: 100%;
  height: 72vh;
  margin: 0;
  padding: 0;
  position: relative;
  /* margin-top: 60px; */
  margin-bottom: 20px;
}

.category-banner-section .offer-label,
.category-banner-section .content-box {
  display: none !important;
}

.category-left-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 3rem;
  position: absolute;
  top: 0;
  left: 0.5rem;
  z-index: 0;
}

.category-left-img-box {
  position: relative;
}

.category-left-img-box::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  /* width: 100%; */
  /* background-color: rgba(0, 0, 0, 0.244); */
  z-index: 1;
  right: 3rem;
}

.category-left-content {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  color: var(--white-color);
  background: transparent;
  z-index: 2;
}

.category-left-content p {
  width: 65%;
  font-size: 1.2rem;
}

.category-left-content .section-title-category-section,
.section-title-category-section-left {
  font-size: 3.5rem;
  color: var(--white-color);
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
}

.category-left-content .section-title-category-section::after {
  background: var(--white-color);
  margin-top: 14px;
}

.section-title-category-section-left::before,
.category-left-content .section-title-category-section::before {
  display: none !important;
}

.category-banner-section .thm-btn {
  /* background: var(--white-color); */
  background: transparent !important;
  color: var(--black-color) !important;
}

.category-banner-slider {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 9;
}

.category-banner-slider h4 {
  color: var(--white-color);
}

.category-banner-slider .slick-slider {
  background: transparent;
  /* padding-left: 2rem;
    padding-right: 1.5rem; */
}

.category-banner-slider .slick-slide {
  padding-right: 0.8rem;
}

.category-banner-slider .category-banner-slider-heading {
  padding-left: 13.5rem;
  padding-right: 0.8rem;
}

.category-banner-slider .item .product-box,
.category-banner-slider .item .product-box .p-images {
  text-align: center;
}

.category-banner-slider .item .product-box .p-images {
  width: 100%;
  /* height: auto; */
  margin: 0;
}

/* .category-banner-section > .row{
  width: 100%;
} */
.category-banner-skeleton .item {
  width: 30%;
  aspect-ratio: 1/1;
}

.category-banner-skeleton .item .ant-skeleton {
  aspect-ratio: 1/1;
}

@media (max-width: 1500px) {
  .category-banner-slider .item .product-box .p-images {
    width: 100% !important;
  }
}

@media (max-width: 1400px) {
  .category-banner-section {
    height: 50vh;
  }
}

@media (max-width: 992px) {
  .category-banner-slider .category-banner-slider-heading {
    padding-left: 0;
  }

  .category-banner-section {
    height: 37vh !important;
  }

  .category-left-content p {
    width: 80%;
    font-size: 1rem;
  }
}

@media (max-width: 568px) {
  .category-left-img-box::before {
    right: 0;
    bottom: 25%;
    height: auto;
  }

  .category-banner-slider .slick-slide {
    padding: 0 0.4rem;
  }

  .category-left-content .section-title-category-section {
    margin-top: 30px;
  }

  .category-banner-section>.row {
    width: 100%;
    margin: 0;
  }

  .category-banner-section {
    height: 54vh !important;
  }

  .category-left-img {
    height: 55%;
    padding: 0;
    left: 0;
  }

  .category-banner-slider {
    top: 95%;
    transform: translateY(-95%);
  }

  .category-left-content {
    top: 0%;
    left: 20%;
    transform: translate(-20%, 0%);
  }

  .category-left-content p {
    width: 100%;
  }

  .category-left-content .section-title-category-section,
  .section-title-category-section-left {
    font-size: 3rem;
  }

  .category-banner-slider {
    padding: 0;
  }

  .category-banner-slider .viewallbtn {
    background: var(--white-color) !important;
    color: var(--black-color) !important;
  }

  .category-banner-skeleton .item {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .category-banner-section {
    height: 100vh !important;
  }
}

@media (max-width: 480px) and (min-width: 380px) {
  .category-banner-section {
    height: 80vh !important;
  }

  .category-banner-slider {
    /* top: 80%;
    transform: translateY(-80%); */
  }
}

@media (max-width: 992px) and (max-height: 500px) {
  .category-banner-section {
    height: 100vh !important;
  }
}
.footer-policy-section .slick-slide > div{
    padding: 0 10px;
    height: 100%;
}
.footer-policy-section .slick-slide > div .card,
.footer-policy-section .slick-slide > div .card .card-body{
    height: 100%;
}
.footer-policy-section .slick-slide .card{
    /* background: var(--theme-color-light); */
    background: #f5f3f0;
    border: none;
    /* color: var(--white-color); */
}
.footer-policy-section .slick-slide .card .card-body{
    display: flex;
    /* align-items: center; */
}
.footer-policy-section .slick-slide .card .card-body img{
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 10px;
    align-self: center;
}
.footer-policy-section .slick-slide .card p b{
    font-size: 1.3rem;
}
@media (max-width:568px) {
    .footer-policy-section .slick-slide .card .card-body{
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 2.2rem;
    }
}
.main-banner-right:nth-child(3),
.main-banner-right:nth-child(3) img,
.main-banner-right:nth-child(4) img,
.main-banner-right:nth-child(4) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.home-slider img {
  width: 100%;
  /* height: 100% !important; */
  /* height: 75vh !important; */
}
.home-slider .slick-list,
.home-slider .slick-list div {
  /* height: 100%; */
}
.home-slider-skeleton {
  height: 75.5vh !important;
}

@media (max-width: 568px) {
  .home-slider .slick-list,
  .home-slider .slick-list div {
    height: auto;
  }
  .home-slider {
    margin-bottom: 1rem;
  }
  .home-slider .slick-prev,
  .home-slider .slick-next {
    display: none !important;
  }
  .main-banner-right img{
    height: auto !important;
    aspect-ratio: 1/1;
  }
  .home-slider-skeleton {
    height: 35vh !important;
  }
}


.parallax-section {
    width: 100%;
    height: 220px !important;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-attachment: fixed !important; */
    color: var(--white-color);
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
.parallax-section h2{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white-color);
    width: 70%;
    text-align: center;
}
.parallax-section p{
    width: 48%;
    text-align: center;

}
.parallax-section .thm-btn{
    background: transparent;
    border: 1px solid var(--white-color);
}
.parallax-section-box{
    padding: 0 1.7rem;
}
@media (max-width:568px) {
    .parallax-section {
        height: 10vh !important;
        text-align: center;
        padding: 1rem;
    }
    .parallax-section h2{
        width: 100%;
        font-size: 2rem;
    }
    .parallax-section p{
        width: 100%;
    }
}
@media (max-width:380px) {
    .parallax-section {
        height: 10vh !important;
    }
}
@media (max-width: 992px) and (max-height:500px){
    .parallax-section{
        height: 70vh !important;
    }
  }
