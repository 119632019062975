.best-seller-card{
    width: 20% !important;
    padding: 0 .4rem !important;
}
.best-seller-card p{
    margin-bottom: 0;
}
.product-img-skeleton{
    /* height: 400px; */
    aspect-ratio: 1/1;
}
.product-img-skeleton img{
    
}
@media (max-width:992px) {
    .best-seller-card{
        width: 50% !important;
    }    
}
@media (max-width:568px) {
    .best-seller-card{
        width: 100% !important;
        padding: 0 .75rem !important;
    }
}