.nav-logo {
  width: 80px;
}

.main-navbar .navbar-toggler {
  border: none !important;
  color: var(--theme-color-dark);
  padding: 0;
  font-size: 1.5rem !important;
}

button:focus-visible,
button:focus {
  border: none !important;
  box-shadow: none !important;
}

.main-nav-right-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav-right-links li:first-child {
  margin-bottom: -2px;
}

.main-nav-right-links li {
  font-size: 1.5rem;
  /* margin-right: 1rem; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.main-nav-right-links li:last-child {
  margin: 0;
}

.main-nav-right-links li span {
  font-size: 0.8rem;
  background: var(--theme-color-light);
  color: var(--white-color);
  border-radius: 5px;
  padding: 4px;
  margin-left: 10px;
  /* width: 30px; */
  text-align: center;
}

.main-nav-right-links li svg {
  color: var(--black-color);
}

/* Main Navbar Menu */
.main-nav-menu-links {
  /* overflow: hidden; */
}

.fixed-nammenu {
  background: var(--white-color);
}

.main-nav-menu-links a {
  margin-right: 2rem;
  font-size: 1.1rem;
  position: relative;
  /* text-transform: uppercase; */
  display: inline-block;
  transition: 0.3s;
}

.main-nav-menu-links a:hover {
  color: var(--theme-color-dark) !important;
}

/* .main-nav-menu-links a::before{
    content: "\f0d7";
    position: absolute;
    font-family: "FontAwesome";
    color: var(--theme-color-dark);
    top: 0;
    left: 50%;
    transform: translate(-50%,-50px);
    transition: .3s; 
    opacity: 0;  
} */
.main-nav-menu-links a:hover::before {
  opacity: 1;
  transform: translate(-50%, -15px);
  z-index: 999;
}

.main-nav-menu-sm-links li a {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 10px;
}

.main-nav-menu-sm-links li:last-child a {
  margin: 0;
}

.main-nav-menu-sm-links li a {
  font-size: 1.35rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
}

.main-nav-menu-sm-links li a svg {
  color: var(--theme-color-dark);
}

.main-nav-menu-sm-links li a:hover {
  background: var(--theme-color-dark);
}

.main-nav-menu-sm-links li a:hover svg {
  color: var(--white-color);
}

/* .main-nav-menu-links li .dropdown-toggle::after{
    content: "\f107";
    font-family: "FontAwsome";
    border: none;
} */
.login-icon svg {
  font-size: 1.5rem;
}

.login-icon {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-menu.show {
  left: -100% !important;
  /* transform: translate3d(-140px, 0px, 0px) !important; */
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent !important;
  color: var(--black-color) !important;
}

.dropdown-toggle:hover {
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-toggle {
  margin-right: 10px;
}

.login-dropdown .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle::after {
  border: none !important;
  /* font: 'fontawesome';
    content: "\f107"; */
  content: "\f107" !important;
  font-family: FontAwesome;
  vertical-align: 0 !important;
  font-weight: bold;
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    margin-top: -4px !important;
  }
}

@media (max-width: 992px) {
  .fixed-nammenu .border-top.border-bottom {
    border-top: none !important;
  }

  .navbar-brand {
    margin-left: 1rem;
  }

  .offcanvas-body .dropdown-menu.show {
    border: none !important;
    padding: 0;
    margin: 0;
  }

  .main-navbar-offcanvas {
    max-width: 80% !important;
  }

  .main-navbar-offcanvas .contact-links {
    align-items: center;
  }

  .main-navbar-offcanvas .contact-links svg {
    font-size: 1.7rem;
    padding: 5px;
    background-color: var(--theme-color-dark);
    color: var(--white-color);
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (min-width:992px) and (max-width:1120px) {
  .main-nav-menu-links a {
    margin-right: 1.6rem !important;
    font-size: 1.0rem;
    position: relative;
    display: inline-block;
    transition: 0.3s;
  }

}