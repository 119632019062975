.main-banner-right:nth-child(3),
.main-banner-right:nth-child(3) img,
.main-banner-right:nth-child(4) img,
.main-banner-right:nth-child(4) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.home-slider img {
  width: 100%;
  /* height: 100% !important; */
  /* height: 75vh !important; */
}
.home-slider .slick-list,
.home-slider .slick-list div {
  /* height: 100%; */
}
.home-slider-skeleton {
  height: 75.5vh !important;
}

@media (max-width: 568px) {
  .home-slider .slick-list,
  .home-slider .slick-list div {
    height: auto;
  }
  .home-slider {
    margin-bottom: 1rem;
  }
  .home-slider .slick-prev,
  .home-slider .slick-next {
    display: none !important;
  }
  .main-banner-right img{
    height: auto !important;
    aspect-ratio: 1/1;
  }
  .home-slider-skeleton {
    height: 35vh !important;
  }
}
