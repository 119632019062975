.category-banner-section {
  width: 100%;
  height: 72vh;
  margin: 0;
  padding: 0;
  position: relative;
  /* margin-top: 60px; */
  margin-bottom: 20px;
}

.category-banner-section .offer-label,
.category-banner-section .content-box {
  display: none !important;
}

.category-left-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 3rem;
  position: absolute;
  top: 0;
  left: 0.5rem;
  z-index: 0;
}

.category-left-img-box {
  position: relative;
}

.category-left-img-box::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  /* width: 100%; */
  /* background-color: rgba(0, 0, 0, 0.244); */
  z-index: 1;
  right: 3rem;
}

.category-left-content {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  color: var(--white-color);
  background: transparent;
  z-index: 2;
}

.category-left-content p {
  width: 65%;
  font-size: 1.2rem;
}

.category-left-content .section-title-category-section,
.section-title-category-section-left {
  font-size: 3.5rem;
  color: var(--white-color);
  grid-template-columns: max-content 1fr;
}

.category-left-content .section-title-category-section::after {
  background: var(--white-color);
  margin-top: 14px;
}

.section-title-category-section-left::before,
.category-left-content .section-title-category-section::before {
  display: none !important;
}

.category-banner-section .thm-btn {
  /* background: var(--white-color); */
  background: transparent !important;
  color: var(--black-color) !important;
}

.category-banner-slider {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 9;
}

.category-banner-slider h4 {
  color: var(--white-color);
}

.category-banner-slider .slick-slider {
  background: transparent;
  /* padding-left: 2rem;
    padding-right: 1.5rem; */
}

.category-banner-slider .slick-slide {
  padding-right: 0.8rem;
}

.category-banner-slider .category-banner-slider-heading {
  padding-left: 13.5rem;
  padding-right: 0.8rem;
}

.category-banner-slider .item .product-box,
.category-banner-slider .item .product-box .p-images {
  text-align: center;
}

.category-banner-slider .item .product-box .p-images {
  width: 100%;
  /* height: auto; */
  margin: 0;
}

/* .category-banner-section > .row{
  width: 100%;
} */
.category-banner-skeleton .item {
  width: 30%;
  aspect-ratio: 1/1;
}

.category-banner-skeleton .item .ant-skeleton {
  aspect-ratio: 1/1;
}

@media (max-width: 1500px) {
  .category-banner-slider .item .product-box .p-images {
    width: 100% !important;
  }
}

@media (max-width: 1400px) {
  .category-banner-section {
    height: 50vh;
  }
}

@media (max-width: 992px) {
  .category-banner-slider .category-banner-slider-heading {
    padding-left: 0;
  }

  .category-banner-section {
    height: 37vh !important;
  }

  .category-left-content p {
    width: 80%;
    font-size: 1rem;
  }
}

@media (max-width: 568px) {
  .category-left-img-box::before {
    right: 0;
    bottom: 25%;
    height: auto;
  }

  .category-banner-slider .slick-slide {
    padding: 0 0.4rem;
  }

  .category-left-content .section-title-category-section {
    margin-top: 30px;
  }

  .category-banner-section>.row {
    width: 100%;
    margin: 0;
  }

  .category-banner-section {
    height: 54vh !important;
  }

  .category-left-img {
    height: 55%;
    padding: 0;
    left: 0;
  }

  .category-banner-slider {
    top: 95%;
    transform: translateY(-95%);
  }

  .category-left-content {
    top: 0%;
    left: 20%;
    transform: translate(-20%, 0%);
  }

  .category-left-content p {
    width: 100%;
  }

  .category-left-content .section-title-category-section,
  .section-title-category-section-left {
    font-size: 3rem;
  }

  .category-banner-slider {
    padding: 0;
  }

  .category-banner-slider .viewallbtn {
    background: var(--white-color) !important;
    color: var(--black-color) !important;
  }

  .category-banner-skeleton .item {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .category-banner-section {
    height: 100vh !important;
  }
}

@media (max-width: 480px) and (min-width: 380px) {
  .category-banner-section {
    height: 80vh !important;
  }

  .category-banner-slider {
    /* top: 80%;
    transform: translateY(-80%); */
  }
}

@media (max-width: 992px) and (max-height: 500px) {
  .category-banner-section {
    height: 100vh !important;
  }
}