@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Courgette&family=Dancing+Script:wght@400..700&display=swap");
/* font-family: "Caveat", cursive; */
/* font-family: "Courgette", cursive; */
/* font-family: "Dancing Script", cursive; */
.variant-image {
  width: 100px;
}
.fs-14 {
  font-size: 14px;
}
.product-detail-content {
  /* height: 601px; */
}
.product-short-description {
  /* font-size: 20px; */
  font-weight: 500;
}
.prod-yousave {
  font-family: var(--theme-font-secondary);
  font-size: 16px;
}
.product-detail-accordion {
  /* height: 180px; */
  margin-top: 0.6rem;
  /* overflow-y: auto; */
}

.product-detail-accordion::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.product-detail-accordion::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
  max-height: 50%;
  height: 50%;
}
.product-detail-accordion::-webkit-scrollbar-thumb {
  height: 10px !important;
}

.product-detail-accordion::-webkit-scrollbar-thumb {
  opacity: 0.6;
  /* height: 20px; */
  border-radius: 10px;
  background-color: var(--theme-color-dark);
}
.product-detail-accordion
  :where(.css-dev-only-do-not-override-1adbn6x).ant-collapse-borderless {
  background: transparent !important;
}

.product-detail-accordion
  :where(.css-dev-only-do-not-override-1adbn6x).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center;
}
.product-detail-accordion .ant-collapse-item-active .ant-collapse-header-text {
  padding-top: 0 !important;
}
.product-detail-accordion .ant-collapse-header-text {
  font-size: 1rem;
  font-weight: 500;
}
.product-detail-accordion .ant-collapse-content-box p {
  font-size: 14px;
}

.product-share-btns {
  position: absolute;
  top: -25px;
  right: 0;
}
.product-share-btns ul {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  list-style: none;
}
.product-share-btns .p-share-btn,
.product-share-btns ul li a,
.product-share-btns ul li button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: var(--white-color) !important;
  background: var(--theme-color-dark) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  margin-top: 10px;
}
.product-share-btns .p-share-btn {
  position: relative;
  z-index: 999;
  background: var(--white-color) !important;
  color: var(--theme-color-dark) !important;
}
.product-share-btns ul li {
  position: absolute;
  top: -10px;
  /* display: none; */
  opacity: 0;
  transition: 1s;
}

.product-share-btns ul li:nth-child(2) {
  /* transition-delay: 0.2s; */
}
.product-share-btns ul li:nth-child(3) {
  /* transition-delay: 0.4s; */
}
.product-share-btns ul li:nth-child(4) {
  /* transition-delay: 0.6s; */
}
.product-share-btns ul li:nth-child(5) {
  /* transition-delay: .8s; */
}
.activ-share-btn .p-share-btn {
  color: var(--white-color) !important;
  background: var(--theme-color-dark) !important;
}
.activ-share-btn li:nth-child(5),
.activ-share-btn li:nth-child(2),
.activ-share-btn li:nth-child(3),
.activ-share-btn li:nth-child(6),
.activ-share-btn li:nth-child(4) {
  opacity: 1;
}
.activ-share-btn li:nth-child(2) {
  top: 28px;
}
.activ-share-btn li:nth-child(3) {
  top: 66px;
}
.activ-share-btn li:nth-child(4) {
  top: 105px;
}
.activ-share-btn li:nth-child(5) {
  top: 142px;
}
.activ-share-btn li:nth-child(6) {
  top: 180px;
}
.product-share-btns .p-share-btn {
  cursor: pointer;
  margin-top: 0;
  border: 1px solid var(--theme-color-dark);
}

.product-detail-img {
  /* height: 601px !important; */
  aspect-ratio: 1/1;
}
.product-detail-img > div {
  height: 100% !important;
}
.product-detail-img img {
  height: 100% !important;
}

.variants .detailscard img {
  width: 100px;
}

.overall-ratings {
  display: flex;
  background-color: #ffffff;
  padding: 0.5rem 0;
  border-right: 2px solid rgba(101, 119, 134, 0.22);
}
.review-card {
  padding: 1rem;
  margin: 1rem;
  position: relative;
  box-shadow: 0 0 10px #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
}
.review-card .css-rwwnk2,
.review-card .userInfoSection,
.review-card .css-1v6g5ho {
  padding: 0;
}

.review-card .css-1n0nrdk {
  height: 60px;
  overflow-y: auto;
  font-size: 0.9rem;
  padding-right: 10px;
}
.review-card .css-1n0nrdk::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6c6a6a;
}
.review-card .UserInformationProductReviews {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.review-card .verified-buyers-icon {
  display: flex;
  align-items: center;
}
.review-card .verified-buyers-icon img {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}
.overall-ratings > img {
  width: 200px;
  height: 200px;
  margin-right: 2rem;
}

.ratings {
  font-size: 36px;

  letter-spacing: -0.3px;
  line-height: 50px;
  margin-right: 0.5rem;
}
.ratings-exact {
  font-size: 36px;

  line-height: 40px;
  letter-spacing: -0.3px;
}

.ratings-upto {
  font-size: 36px;

  line-height: 40px;
}

.ratings-static {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(0, 19, 37, 0.92);
  display: block;
  margin-top: 0.5rem;
}

.ratings-verified {
  line-height: 16px;
  flex: 2 1 0%;
}

.verified-upto {
  font-size: 13px;

  line-height: 20px;
  letter-spacing: 0px;
  color: rgba(0, 19, 37, 0.72);
}

.writereview {
  font-size: 14px;

  line-height: 20px;
  letter-spacing: 0px;
  font-stretch: normal;
  font-style: normal;
  color: rgba(0, 19, 37, 0.72);
  padding-bottom: 0.5rem;
}

.customer-reviews {
  padding: 25px 75px;
}

.customer-reviews-Description {
  display: flex;
  width: 100%;
  /* border-bottom: 1px solid rgba(101, 119, 134, 0.12); */
  padding: 25px 55px;
}
.product-detail-content .ant-select-selection-item p {
  margin: 0;
}
.pers-box {
  padding: 1rem;
  border: 1px solid #d9d9d9;
  width: 50%;
}
.pers-btns button {
  background: var(--white-color);
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-right: 1rem;
  padding: 5px 15px;
}
.pers-btns button:focus-visible {
  outline: none !important;
}
.pers-btns button.active {
  background: var(--theme-color-light);
  color: var(--white-color);
  border-color: var(--theme-color-light);
}
.pers-btns button:last-child {
  margin: 0;
}
.pers-input-box {
  /* gap: 10px; */
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* .pers-input-box > div:nth-child(1){
  margin-right: 13px;
} */
.pers-input-box > div {
  width: 100%;
}
.pers-input-box input {
  border: 1px solid #d9d9d9;
  padding: 5px 11px;
  border-radius: 6px;
  width: 100%;
}
.pers-input-box p {
  margin: 0;
}
.pers-prev {
  margin-top: 1rem;
  padding: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.detailscard {
  width: 100px;
  margin-right: 1rem;
}

.detailscard p {
  padding: 5px;
  margin-bottom: 0;
  line-height: normal;
}
.related-product-slider .slick-list .slick-track {
  margin: 0;
}
.product-detail-accordion td{
  padding: 10px;
}
.product-detail-accordion tbody,
  .product-detail-accordion td,
  .product-detail-accordion tfoot,
  .product-detail-accordion th,
  .product-detail-accordion thead,
  .product-detail-accordion tr {
    border-width: 1px !important;
  }
  .product-box img{
    aspect-ratio: 1/1;
    width: 100%;
  }
@media (max-width: 1090px) and (min-width: 480px) {
  .product-detail-img {
    height: 370px !important;
  }
  .product-detail-content {
    height: auto;
  }
  .product-share-btns {
    top: -20px;
  }
}
@media (max-width: 992px) and (min-width: 480px) {
  .product-detail-img {
    /* height: 370px !important; */
  }

  .product-detail-content {
    height: auto !important;
  }

  .buttons.detailsbtn {
    margin-top: 0;
  }

  .products-inner .prod-title {
    font-size: 2rem;
    padding-right: 3rem;
  }

  .product-short-description {
    font-size: 0.9rem !important;
    padding-right: 3rem;
  }
  .pers-box {
    width: 100%;
  }
}

@media (max-width: 568px) {
  .product-detail-accordion table {
    width: 100% !important;
    overflow: scroll !important;
  }
  
  .product-detail-content {
    margin-top: 1rem;
  }
}
