.quantity-btn-box {
    display: flex;
    background: var(--white-color);
    color: var(--theme-color-light);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--theme-color-light);
    height: 32px;
    width: fit-content;
}

.quantity-btn-box .quantity-btn svg {
    color: var(--theme-color-light);
}

.quantity-btn-box .quantity-delete-btn {
    height: 100%;
    padding: 4px;
}

.quantity-btn-box .quantity-btn {
    font-size: .9rem;
    height: 100%;
}

.quantity-btn-box button {
    background: transparent;
    border: none;
    color: var(--theme-color-light);
    padding: 0;
    height: 100%;
    padding: 0 2px;
}

.quantity-btn-box button svg {
    margin-top: -4px;
}

.quantity-btn-box input {
    max-width: 30px;
    text-align: center;
    margin: 0 5px;
    background: transparent;
    border: none;
    color: var(--theme-color-light);
    border-left: 1px solid var(--theme-color-light);
    border-right: 1px solid var(--theme-color-light);
    height: 100%;
}

.quantity-delete-btn {
    height: 100%;
    background: var(--white-color);
    color: var(--theme-color-light);
    font-size: 1.4rem;
    margin-left: 5px;
    border-left: 1px solid var(--theme-color-light);
}

.quantity-delete-btn cart-remove-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}