.disclaimer-box .ant-collapse {
  margin-bottom: 1rem;
  overflow: hidden;
}
.disclaimer-box .ant-collapse-header {
  flex-direction: row-reverse;
  background: #fff !important;
  border: none !important;
  align-items: center;
}
.disclaimer-box .ant-collapse-content {
  padding: 0.7rem 1rem 1rem;
  font-size: 1rem;
}
@media (max-width: 568px) {
  .disclaimer-box .ant-collapse-header-text {
    padding-right: 1rem;
  }
}
