
.parallax-section {
    width: 100%;
    height: 220px !important;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /* background-attachment: fixed !important; */
    color: var(--white-color);
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
.parallax-section h2{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white-color);
    width: 70%;
    text-align: center;
}
.parallax-section p{
    width: 48%;
    text-align: center;

}
.parallax-section .thm-btn{
    background: transparent;
    border: 1px solid var(--white-color);
}
.parallax-section-box{
    padding: 0 1.7rem;
}
@media (max-width:568px) {
    .parallax-section {
        height: 10vh !important;
        text-align: center;
        padding: 1rem;
    }
    .parallax-section h2{
        width: 100%;
        font-size: 2rem;
    }
    .parallax-section p{
        width: 100%;
    }
}
@media (max-width:380px) {
    .parallax-section {
        height: 10vh !important;
    }
}
@media (max-width: 992px) and (max-height:500px){
    .parallax-section{
        height: 70vh !important;
    }
  }