.category-image-header {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}
.product-filter-list{
  display: flex;
}
.category-wrapper-header {
  width: 50%;
  text-align: center;
}
.category-wrapper-header a{
  display: flex;
}