.distributors-section {
  background: #f5f3f0;
}
.process {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}
.process__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  position: relative;
  padding: 15px 35px;
  transition: 0.4s ease-in-out;
}
.process__item:hover {
  background: #f2f2f2;
}
.process__item:hover .process__number {
  transform: translateY(5px);
  color: var(--theme-color-dark);
}
.process__number {
  font-size: 90px;
  -webkit-text-stroke: 1px var(--theme-color-dark);
  display: block;
  color: transparent;
  font-weight: 700;
  transition: 0.4s ease-in-out;
}
.process__title {
  display: block;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 1.2rem;
  color: var(--theme-color-dark);
  text-transform: uppercase;
  margin-top: 30px;
}
.process__title a {
  color: var(--theme-color-dark) !important;
}
.process__subtitle {
  display: block;
  font-size: 20px;
  color: #8c8c8c;
  margin-top: 30px;
  line-height: 1.5;
}
.vm-card :is(h2,p) {
  color: var(--white-color);
}
.vm-card {
  text-align: center;
  border: 1px solid var(--white-color);
  padding: 1rem;
  height: 100%;
  border-radius: 5px;
}

/* new  */

.ps-section--team {
  /* background-color: #fff; */
}

.ps-section--team .ps-section__title {
  margin-bottom: 20px;
}

.ps-section--team .ps-section__header p {
  margin-bottom: 25px;
}

.ps-section--team .ps-list--dot {
  margin-bottom: 35px;
}

.vm-card .icon {
  background: var(--theme-color-dark);
  width: 120px;
  height: 120px;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.work-process-area {
  overflow: hidden;
  /* margin-bottom: -8px; */
}

.works-process-items {
  float: left;
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: 30px;
}

.works-process-items .item {
  padding: 0 15px;
}

.works-process-items::after {
  border-top: 1px solid #e7e7e7;
  content: "";
  height: 1px;
  left: -50%;
  margin-top: 40px;
  position: absolute;
  top: 0;
  width: 200%;
  z-index: -1;
}

.work-process-area {
  overflow: hidden;
  margin-bottom: -8px;
}

.works-process-items .item .info h4 {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: capitalize;
}

.works-process-items .item .info span {
  color: var(--theme-color-dark);
  display: block;
  font-family: Poppins, sans-serif;
  margin-bottom: 10px;
}

.works-process-area .works-process-items .info p {
  margin: 0;
}

.works-process-items .item .icon {
  height: 160px;
  position: relative;
  text-align: center;
}

.works-process-items .item .icon i {
  background: var(--theme-color-dark) none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 50px;
  height: 100px;
  left: 50%;
  line-height: 100px;
  margin-left: -50px;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 100px;
  z-index: 1;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.works-process-items .item .icon.icon-down i {
  bottom: 0;
  top: auto;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.works-process-items .item:hover .icon i {
  background: #ef1a24 none repeat scroll 0 0;
  color: #fff;
}
.work-process-area.bg-gray .works-process-items .item .vertical-line span {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .vertical-line {
  line-height: 0;
  margin: 15px 0;
  position: relative;
  text-align: center;
}

.works-process-items .item .vertical-line span {
  background: #f4f4f4 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #232323;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 800;
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 50px;
}

.work-process-area.bg-gray .works-process-items .item .vertical-line span {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .info h4 {
  font-weight: 500;
}

.works-process-items .item .info p {
  margin: 0;
}

.works-process-items .item .vertical-line span::before {
  background: #d1d1d1 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100px;
  left: 50%;
  position: absolute;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  width: 1px;
  z-index: -1;
}

.work-process-area.bg-gray
  .works-process-items
  .item
  .vertical-line
  span::before {
  background: #e7e7e7 none repeat scroll 0 0;
}

.works-process-items .item .vertical-line span::after {
  background: #d7d7d7 none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  bottom: 100px;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  width: 10px;
  z-index: -1;
}

.works-process-items .item .vertical-line.bottom span::before {
  bottom: auto;
  top: 0;
}

.works-process-items .item .vertical-line.bottom span::after {
  bottom: auto;
  top: 100px;
}

.works-process-items .item .info {
  text-align: center;
}

.works-process-items .item:hover .vertical-line span::before {
  height: 0;
}

.works-process-items .item:hover .vertical-line span::after {
  bottom: 0;
}

.works-process-items .item:hover .vertical-line.bottom span::after {
  top: 0;
}

.inner-icon i {
  font-size: 3rem;
  color: var(--theme-color-dark);
}

.clenzrooms-how-work-section {
  padding: 120px 0px;
  background-color: #f2fcfc;
}

.about-ruby {
  background: #f5f3f0;
}
.about-ruby {
  padding-top: 70px;
}
.about-ruby .line-shape {
  left: 0;
  top: 60px;
}

.about-ruby-item .serial {
  left: 0;
  right: 0;
  top: -15px;
  z-index: 2;
  color: #fff;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  font-weight: 600;
  border-radius: 100%;
  background-color: var(--theme-color-dark);
}
.about-ruby-item .inner-icon {
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.about-ruby-item .inner-icon::before,
.about-ruby-item .inner-icon::after {
  left: 0;
  top: 10px;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: var(--theme-color-dark);
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
}
.about-ruby-item .inner-icon::after {
  top: 0;
  background-color: #fff;
}
.about-ruby-item .inner-text h3 {
  color: #101010;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
}
.about-ruby-item:hover .inner-icon::after {
  background-color: var(--theme-color-dark);
}
.about-ruby-item:hover .inner-icon::before {
  background: var(--white-color);
}

.about-ruby-item:hover i {
  color: #fff;
}
.vm-section {
  position: relative;
  background: var(--theme-color-dark);
}
.vm-section .svg-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  top: -1px;
}
.vm-section svg {
  background: var(--theme-color-dark);
  height: clamp(3.125rem, 2.1154rem + 4.4872vw, 7.5rem);
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.vision-mission-section {
  padding-top: clamp(7.125rem, 8.1154rem + 8.4872vw, 9.5rem);
  margin-bottom: 7rem;
}
.vm-right-img{
  width: 320px;
  height: 320px;
  mask-image: url(../../assets/Images/vm-bg.png);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.vm-right-img img{
  height: 70%;
  width: 70%;
  border-radius: 50%;
}
.vm-divider{
  display: flex;
  list-style: none;
}
.vm-divider li{
  width: 20px;
  height: 20px;
  background: var(--white-color);
  border-radius: 50%;
  margin-right: 1rem;
}
.vm-divider li:first-child{
  width: 80px;
  border-radius: 15px;
}
.vm-bottom-img-section{
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: -50%;
  overflow: hidden; */
  /* position: relative;
  bottom: -70%; */
  margin-top: -4rem !important;
  transform: translateY(50%);
  background: transparent;
}
.vm-bottom-img-left{
  border: 2px solid var(--white-color);
  transform: rotate(12deg);
}
.vm-bottom-img-right{
  border: 2px solid var(--white-color);
  transform: rotate(-12deg);
}
@media (max-width: 1199px) {
  .ps-section--team .ps-section__title::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .ps-section--team .ps-section__header {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .ps-section--team .ps-section__content .col-xs-12 {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .ps-section--team .ps-section__content .col-xs-12 {
    width: 100%;
  }
  .vision-mission-section{
    padding-top: 5rem;
    overflow-x: hidden;
    margin-bottom: 0;
  }
  .vm-right-img{
    margin: 0 auto;
  }
  .vm-bottom-img-section{
    transform: translateY(0);
    margin-top: 2rem !important;
  }
}

@media (min-width: 768px) {
  .process {
    display: inline-block;
  }
  .process__item {
    width: 49%;
    display: inline-block;
  }
  
}
@media (min-width: 1200px) {
  .process {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .process__item {
    width: 100%;
  }
  .process__item:not(:last-of-type)::after {
    content: "";
    width: 1px;
    height: 75%;
    background: #8c8c8c;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0.2;
    transform: translateY(-50%);
  }
}
