.btn-send {
  background-color: var(--theme-color-dark);
  /* color: var(); */
}
.table {
  --bs-table-bg: initial !important;
  --bs-table-color: initial !important;
}
.table .table-color {
  background: var(--theme-color-dark) !important;
  color: #fff !important ;
}

.table-katagiri {
  background-color: #1aaf20 !important;
  color: #fff !important;
}

.coimbatore {
  background: var(--theme-color-dark) !important;
  color: #fff !important;
}

.table-info {
  background-color: blue !important;
}

.Cuddalore {
  background-color: #ff9800 !important;
  color: #fff;
}

.dharmapuri {
  background-color: #673ab7 !important;
  color: #fff;
}

.dindigul {
  background-color: #989803 !important;
  color: #fff;
}

.Erode {
  background-color: #ff00ff !important;
  color: #fff;
}

.Kanchipuram {
  background-color: var(--theme-color-dark) !important;
  color: #fff;
}

.karur {
  background-color: #0091ff !important;
  color: #fff;
}

.Krishnagiri {
  background-color: #b8860b !important;
  color: #fff;
}

.Nagapattinam {
  background-color: #ff9800 !important;
  color: #fff;
}
.Namakkal {
  background-color: #ff00ff !important;
  color: #fff;
}

.Ramnad {
  background-color: #989803 !important;
  color: #fff;
}

.tanjore {
  background-color: #1aaf20 !important;
  color: #fff;
}
.distributors-form-box{
    background: #faf8f8;
}
.distributors-form.row {
  background: #faf8f8;
  padding: 1rem 0;
  height: 100%;
}
.distributors-acoordion{
    padding: 10px;
    border: 1px solid #d9d9d9;
}
.distributors-acoordion .ant-collapse{
  border: none;
}
.distributors-acoordion :where(.css-dev-only-do-not-override-11xg00t).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
    border-radius: 0;
}
.distributors-acoordion .ant-collapse {
  margin-bottom: 1rem;
  overflow: hidden;
}
.distributors-acoordion .ant-collapse-header {
  flex-direction: row-reverse;
  background: #fff !important;
  border: none !important;
  align-items: center;
}
.distributors-acoordion .ant-collapse-content {
  padding: 0.7rem;
  font-size: 1rem;
  max-height: 200px;
  overflow-y: auto;
}
.distributors-acoordion .ant-collapse-content-box > div {
  columns: 2 100px;
}
.distributors-acoordion .ant-collapse-content b {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
}
.distributors-acoordion .ant-collapse-content p {
  margin-bottom: 10px;
  text-transform: capitalize;
}
.distributors-acoordion .ant-collapse-content p:last-child {
  margin-bottom: 0;
}
@media (max-width: 568px) {
  .distributors-acoordion .ant-collapse-header-text {
    padding-right: 1rem;
  }
  .distributors-acoordion{
    margin-bottom: 1.5rem;
  }
}
